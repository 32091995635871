import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) { }

  snackBarMessage(message: any, action: any) {
    this._snackBar.open(
      message,
      action,
      {
        duration: 2000
      }
    )
  }


  getThemes(data: any) {
    return this.httpClient.put(
      environment.baseUrl + `business/ssp/url`,
      data
    )
  }
}
