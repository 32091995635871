import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
  ) { }


  openDialog(componentName: any, height: any, width: any, dataModel: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
    });
  }

  signIn(data: any): Observable<any> {
    const data$ = new ReplaySubject<any>();
    this.http.post(
      environment.baseUrl + `registration/ssp/staffLogin`,
      data
    ).subscribe({
      next: (res) => {
        const data = res?.data;
        data$.next(data);
      },
      error: (err) => {
        data$.error(err)
      }
    })

    return data$;
  }



  // forget password 

  forgetPassword(data: any): Observable<any> {
    const data$ = new ReplaySubject<any>();
    this.http.put(
      environment.baseUrl + `staff/ssp/verify/useName?userName=${data.userName}&businessId=${data.businessId}`,
      data
    ).subscribe({
      next: (res) => {
        const data = res?.data;
        data$.next(data);
      },
      error: (err) => {
        data$.error(err)
      }
    })

    return data$;
  }



  // send otp 

  sendOtp(data: any): Observable<any> {
    const data$ = new ReplaySubject<any>();
    this.http.put(
      environment.baseUrl + `staff/ssp/verify/otp`,
      data
    ).subscribe({
      next: (res) => {
        const data = res?.data;
        data$.next(data);
      },
      error: (err) => {
        data$.error(err)
      }
    })

    return data$;
  }


  // set new password 

  setnewPassword(data: any): Observable<any> {
    const data$ = new ReplaySubject<any>();
    this.http.put(
      environment.baseUrl + `staff/ssp/change/login-details?staffId=${data.staffId}&newPassword=${data.newPassword}`,
      {}
    ).subscribe({
      next: (res) => {
        const data = res?.data;
        data$.next(data);
      },
      error: (err) => {
        data$.error(err)
      }
    })

    return data$;
  }


  refreshToken(): Observable<any>{
    // return this.httpClient.get(
    //   environment.baseUrl+`registration/token`
    // )

    const data$ = new ReplaySubject<any>();
    this.http.get(
      environment.baseUrl + `registration/token`
    ).subscribe({
      next: (res) => {
        const data = res;
        data$.next(data);
      },
      error: (err) => {
        data$.error(err)
      }
    })

    return data$;
  }
}
